import {Region} from '@coveo/platform-client';
import {sanitizeRegion} from '../Sanitization';

const placeholder = '{region}';

const injectRegion = (url: string, region: Region | string, isOriginUs?: boolean): string => {
    const endpointRegion = region === Region.US && !isOriginUs ? '' : `-${sanitizeRegion(region)}`;
    const matcher = new RegExp(placeholder, 'g');
    return url?.replace(matcher, endpointRegion) ?? '';
};

const extractRegion = (url: string, _window = window): Region | string => {
    const hostRoots = [
        'platformdev',
        'platformstg',
        'platform',
        'commercedev',
        'commercestg',
        'commerce',
        'knowledge',
        'knowledgestg',
        'knowledgedev',
    ];
    const allowedRegions = Object.freeze(Object.values(Region));
    const params = new URLSearchParams(_window.location.search);

    // Looking for injected region in the querystring parameter &region=theRegion
    // If the region is valid and includes a region from @coveord/platform-client Region enum, we return it
    const injectedRegion = params.get('region');
    if (injectedRegion && allowedRegions.includes(injectedRegion as Region)) {
        return injectedRegion;
    }

    // Extracting the region from the url hostname
    for (let i = 0; i < hostRoots.length; i++) {
        const EXTRACT_REGION = new RegExp(`${hostRoots[i]}-(\\w+)\\.cloud\\.coveo\\.com`); // extracts "eu" from "platfrom-eu.cloud.coveo.com"
        const matches = EXTRACT_REGION.exec(url);
        if (matches?.[1] && allowedRegions.includes(matches[1] as Region)) {
            return matches[1];
        }
    }

    return Region.US;
};

export const RegionUtils = {
    injectRegion,
    extractRegion,
    placeholder,
};
